import { PostHog } from "posthog-js";

interface BasePayload {
  [key: string]: any;
}

interface TrackParams {
  eventName: string;
  userId: string;
  clientName: string;
  device: string;
  additionalPayload?: BasePayload;
}

export class EmitAnalyticsEvent {
  private posthog: PostHog | null;

  constructor(posthogInstance: PostHog | null) {
    this.posthog = posthogInstance;
  }

  run({ eventName, userId, clientName, device, additionalPayload = {} }: TrackParams): void {
    if (!this.posthog) {
      console.warn("PostHog instance not initialized.");
      return;
    }

    if (!userId || !clientName || !device || !eventName) {
      console.warn("Missing parameter on event payload.");
      return;
    }

    const basePayload = {
      userId,
      companyName: clientName,
      device,
    };

    const payload = { ...basePayload, ...additionalPayload };
    this.posthog.capture(eventName, payload);
  }
}
