import { formatNumber, toFixed2 } from "utils/formators";
import { MAP_TYPES } from "pages/constants";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { capitalize } from "lodash";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { useState, useEffect } from "react";
import { Cursor } from "components/icons/components/Cursor";
import { CircleSelectorIcon } from "components/icons/components/CircleSelectorIcon";
import { makeStyles } from "@material-ui/core";
import cn from "classnames";
import { SettingsIcon } from "components/icons/components/Settings";
import { useEDDMCostsCalculation } from "Hooks/useEDDMCostsCalculation";
import { styles } from "./styles";
import { Accordion, AccordionTrigger, AccordionDetails } from "../../components/AddressesBlock/AddressBlock";

const useStyles = makeStyles(() => styles);

export const AreaStatistics = ({ isCalculatingRoutesIntercection, intersectedRoutesRef }) => {
  const classes = useStyles();
  const { calculateIntercectedRoutesCost } = useEDDMCostsCalculation();
  const [isOpen, setIsOpen] = useState(false);
  const [mobRoute, setMobRoute] = useState(null);
  const [totalCost, setTotalCost] = useState(null);
  const [blockData, setBlockData] = useState(null);

  useEffect(() => {
    if (isCalculatingRoutesIntercection) {
      setBlockData(null);
      setTotalCost(null);
    } else if (!isCalculatingRoutesIntercection && !!intersectedRoutesRef.current) {
      const { cost, business, routes, flyers } = calculateIntercectedRoutesCost([
        ...intersectedRoutesRef.current?.values(),
      ]);
      setBlockData({ BUSINESS_C: business, HOME_COUNT: flyers, APT_COUNT: 0 });
      setTotalCost(cost);
    }
  }, [isCalculatingRoutesIntercection, intersectedRoutesRef.current]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const getBusinessCount = () => {
    if (isCalculatingRoutesIntercection) {
      return <Skeleton width={50} height={20} animation="wave" />;
    } else if (!isCalculatingRoutesIntercection && blockData) {
      return formatNumber(blockData.BUSINESS_C);
    }
  };
  const getAddressesCount = () => {
    if (isCalculatingRoutesIntercection) {
      return <Skeleton width={50} height={20} animation="wave" />;
    } else if (!isCalculatingRoutesIntercection && blockData) {
      return formatNumber(blockData.HOME_COUNT + blockData.APT_COUNT);
    }
  };
  const getTotalCost = () => {
    if (isCalculatingRoutesIntercection) {
      return <Skeleton width={50} height={20} animation="wave" />;
    } else if (!isCalculatingRoutesIntercection && typeof totalCost === "number") {
      return `$ ${formatNumber(toFixed2(totalCost))}`;
    }
  };

  const getTotalRoutes = () => {
    if (isCalculatingRoutesIntercection) {
      return <Skeleton width={50} height={20} animation="wave" />;
    } else {
      return intersectedRoutesRef?.current?.size || 0;
    }
  };

  return (
    <div className={cn(classes.statisticsBlockContainer, "mob-hidden")}>
      <>
        <div className={classes.statisticsHeaderWrapper}>
          <span className={classes.areaStatistics}>
            <CircleSelectorIcon /> <span className={classes.areaStatisticsTitle}>Statistics within area</span>
          </span>
        </div>

        <div className={classes.statistics}>
          <div className={classes.statisticsSection}>
            <span className={classes.statisticsAmount}>{getBusinessCount()}</span>
            <span className={classes.statisticsLabel}>Business</span>
            <span className={classes.satisticsNotTargeted}>{`(not targeted)`}</span>
          </div>
          <div className={classes.verticalSeparator} />
          <div className={classes.statisticsSection}>
            <span className={classes.statisticsAmount}>{getAddressesCount()}</span>
            <span className={classes.statisticsLabel}>Residences</span>
          </div>
          <div className={classes.verticalSeparator} />
          <div className={classes.statisticsSection}>
            <span className={classes.statisticsAmount}>{getTotalCost()}</span>
            <span className={classes.statisticsLabel}>Cost</span>
          </div>
        </div>
      </>

      <div className={classes.areaStatisticsRoutesContainer}>
        <span className={classes.areaStatisticsRoutes}>
          <span className={classes.areaStatisticsRoutesLabel}>Routes: </span>
          {getTotalRoutes()}
        </span>
      </div>
    </div>
  );
};
