export const stylesV2 = {
  addressesContainer: {
    position: "absolute" as const,
    left: "0px",
    top: "0px",
    backgroundColor: "#FFFFFF",
    width: "360px",
    height: "calc(100vh - 80px)",
    padding: "12px",
    display: "flex",
    flexDirection: "column" as const,
    overflow: "scroll",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    fontWeight: 600,
    fontFamily: "'Proxima Nova', 'sans-serif'",
    fontSize: "18px",
    marginTop: "32px",
    marginBottom: "5px",
  },
  searchBarWrapper: {
    display: "flex",
    width: "100%",
    marginBottom: "20px",
  },
  emptyStateContainer: {
    height: "100%",
    flexDirection: "column" as const,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  emptyStateText: {
    width: "184px",
    marginTop: "10px",
    textAlign: "center" as const,
  },
};
