export const styles = {
  routeCardContainer: {
    width: "100%",
    borderTop: "1px solid #E2E2FC",
    borderLeft: "1px solid #E2E2FC",
    borderRight: "1px solid #E2E2FC",
    background: "#F9F9FF",
    padding: "5px 8px",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    height: "66px",
    position: "relative" as const,
  },
  routeHeader: {
    width: "100%",
    minHeight: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  routeTitle: {
    display: "flex",
    alignItems: "center",
    fontFamily: "'Proxima Nova', 'sans-serif'",
    maxWidth: "90%",
  },
  shortTitle: {
    maxWidth: "65%",
  },
  name: {
    marginLeft: "5px",
    fontWeight: 400,
    fontFamily: "'Proxima Nova', 'sans-serif'",
    whiteSpace: "nowrap" as const,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  deleting: {
    backgroundColor: "#FF798D1F",
    borderTop: "1px solid #FFA4B5",
    borderLeft: "1px solid #FFA4B5",
    borderRight: "1px solid #FFA4B5",
  },
  deleteConfirmationButtonsWrapper: {
    display: "flex",
  },

  routeStatistics: {
    width: "100%",
    marginTop: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: "'Proxima Nova', 'sans-serif'",
    fontSize: "12px",
  },
  statisticLabel: {
    color: "#707087",
  },
};
