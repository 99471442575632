const style = (theme) => {
  return {
    headerWrapper: {
      background: "#F9F9FF",
      boxShadow: "0px 2px 14px rgba(39, 55, 84, 0.08)",
      width: "fit-content",
      minWidth: "100vw",
      height: "auto",
      position: "relative",
      top: 0,
      zIndex: 3,
    },

    notVerifiedWrapper: {
      height: "auto",
    },

    eddmHeaderWrapper: {
      background: "#FFFFFF",
      boxShadow: "none",
      height: "auto",
    },

    container: {
      width: "100%",
      height: "80px",
      display: "flex",
      justifyContent: "space-between",
    },

    flyerContainer: {
      width: "100%",
      height: "80px",
      display: "flex",
      alignItems: "center",
      paddingTop: 10,
      paddingBottom: 10,
    },

    nameAndBadgeWrapper: {
      display: "flex",
      alignItems: "center",

      "& > p": {
        color: "#011533",
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "26px",
      },
    },

    homeAndBackWrapper: {
      display: "flex",
      alignItems: "center",
      marginLeft: "56px",
    },

    firstHeaderItem: {
      flex: 1,
      maxWidth: "33%",
      [theme.breakpoints.down(1520)]: {
        maxWidth: "25%",
      },
      [theme.breakpoints.down(1200)]: {
        maxWidth: "15%",
      },
    },

    middleHeaderItem: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "33%",
      [theme.breakpoints.down(1520)]: {
        maxWidth: "25%",
      },
      [theme.breakpoints.down(1200)]: {
        maxWidth: "35%",
      },
    },

    lastHeaderItem: {
      flex: 1,
      maxWidth: "33%",
      [theme.breakpoints.down(1520)]: {
        maxWidth: "50%",
      },
    },

    gridWrapper: {
      position: "relative",
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridTemplateRows: "100%",
      height: "100%",
      width: "100%",
      margin: "0 20px",
    },

    backBtn: {
      cursor: "pointer",
      display: "flex",
      transition: ".3s all",
      width: "fit-content",

      "& > img": {
        marginRight: "18px",
        marginBottom: "auto",
      },
      "& > p": {
        color: "#011533",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "20px",
      },
    },

    homeBtn: {
      marginRight: "40px",
    },

    tabsWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    tabWrapper: {
      display: "flex",
      alignItems: "center",
      marginLeft: "6px",
    },

    tab: {
      cursor: "default",
      color: "rgba(0, 0, 0, 0.26)",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px",
    },

    activeTab: {
      color: "#000000",
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "20px",
    },

    link: {
      cursor: "pointer",
    },

    alertLink: {
      color: "#0C172C",
      marginLeft: 20,
      textDecoration: "underline",
      fontSize: 16,
    },

    separator: {
      background: "black",
      height: "2px",
      width: "30px",
      margin: "0 12px",
    },

    disabled: {
      background: "rgba(0, 0, 0, 0.26)",
    },

    navigationBtnsWrapper: {
      "& > img, & > div": {
        cursor: "pointer",
        marginLeft: "18px",
      },

      "& > div": {
        display: "flex",
        alignItems: "center",
        "& > span": {
          marginLeft: "10px",
          fontWeight: "bold",
          fontSize: "16px",
        },
      },
    },
    navigationHelp: {
      gridColumn: "9 / 13",
      display: "flex",
      justifyContent: "right",
      alignItems: "center",
      "@media (max-width: 1200px)": {
        gridColumn: "8 / 13",
      },
      "@media (max-width: 900px)": {
        gridColumn: "7 / 13",
      },
    },
    newNavigationBtnsWrapper: {
      gridColumn: "1 / 3",
      display: "flex",
      alignItems: "center",
      marginRight: 0,
    },
    stepsContainer: {
      gridColumn: "5 / 9",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "@media (max-width: 1400px)": {
        gridColumn: "5 / 8",
      },
      "@media (max-width: 1200px)": {
        gridColumn: "4 / 8",
      },
      "@media (max-width: 1100px)": {
        gridColumn: "4 / 7",
      },
    },
    homeBtnContainer: {
      marginRight: "16px",

      "@media (max-width: 768px)": {
        marginRight: 0,
      },
    },

    navigationBtn: {
      cursor: "pointer",
      border: "1px solid #273754",
      borderRadius: "16px",
      padding: "14px 52px",
      color: "#00112B",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "20px",
      transition: ".3s all",
      margin: "0 6px",
      alignItems: "center",
      display: "flex",
      position: "relative",

      "&:hover": {
        transform: "scale(1.02)",
      },
    },

    navigationActive: {
      background: "#EE4360",
      border: "none",
      paddingRight: "65px",
      color: "#FFFFFF",
    },

    navigationDisabled: {
      background: "#C2C8D1",
      border: "none",
      paddingRight: "60px",
      color: "#FFFFFF",
    },

    marginRight20: {
      marginRight: "20px",
    },
    item: {
      padding: "10px",
      borderRadius: "8px",
      display: "flex",
      color: "rgb(46, 56, 96);",
      height: "100%",
      alignItems: "center",
    },
    displayNone: {
      display: "none",
    },
    itemCanBeActive: {
      cursor: "pointer",
      "&:hover": {
        background: "#E2E2FC",
      },
    },

    dotsContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    dot: {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "#d3d3d3",
      margin: "0 4px",
    },
    activeDot: {
      backgroundColor: "#000",
      width: 10,
      height: 10,
    },
    mobTabWrapper: {
      position: "relative !important",
      display: "flex !important",
      flexDirection: "column !important",
      height: "90%",
      alignItems: "center",
      justifyContent: "space-around",
    },
    costAndResidencesWrapper: { display: "flex", alignItems: "center", marginLeft: "35px" },
    costAndResidences: { display: "flex", flexDirection: "column", alignItems: "end", marginRight: "18px" },
    cost: {
      fontSize: "18px",
      fontWeight: 700,
    },
    residences: {
      fontSize: "12px",
      fontWeight: 400,
      color: "#707087",
    },
  };
};

export default style;
