import cn from "classnames";
import { DistributionLocation, SelectedRoute } from "module/eddm/dto";
import { LocationLatLngType } from "components/place-autocomplete/type";
import { GoogleMap } from "@react-google-maps/api";
import { VisiblePostCodeType } from "store/types";
import { useStore } from "store";
import { makeStyles, Box } from "@material-ui/core";
import { PlaceAutocomplete } from "components/place-autocomplete";
import { EmptyAddressesState } from "./EmptyAddressesState";
import { AddressCardV2 } from "./AddressCard/AddressCardV2";
import { stylesV2 } from "./stylesV2";

const useStyles = makeStyles(() => stylesV2);

interface AddressBlockProps {
  addresses: DistributionLocation[];
  onAddressCardClick: (address: DistributionLocation) => void;
  handleDeleteLocation: (deletedLocation: DistributionLocation) => void;
  handleAutocompleteChanged: (autocompleteResult: LocationLatLngType) => void;
  getFilteredPostCodes?: (key: string) => VisiblePostCodeType[];
  selectedRoutes: SelectedRoute[];
  setSelectedRoutes: (selectedRoutes: SelectedRoute[] | ((selectedRoutes: SelectedRoute[]) => SelectedRoute[])) => void;
  googleMap: GoogleMap | null;
  setShouldAutoSave: (shouldAutoSave: boolean) => void;
  setIsUndoOrRedo: (isUndoOrRedo: boolean) => void;
  setIsSingleRouteSelection: (isSingleSelection: boolean) => void;
}

export const AddressBlockV2 = ({
  addresses,
  onAddressCardClick,
  handleDeleteLocation,
  handleAutocompleteChanged,
  getFilteredPostCodes,
  selectedRoutes,
  setSelectedRoutes,
  googleMap,
  setShouldAutoSave,
  setIsUndoOrRedo,
  setIsSingleRouteSelection,
}: AddressBlockProps) => {
  const {
    country,
    map: { loading },
    campaign: { isSubmitted },
  } = useStore();

  const classes = useStyles();

  if (!addresses || addresses.length === 0) return null;

  return (
    <div className={cn(classes.addressesContainer, "mob-hidden")}>
      <div className={classes.title}>Choose the delivery locations</div>
      <Box className={cn(classes.searchBarWrapper)}>
        <PlaceAutocomplete
          countryCode={country?.code}
          disabled={isSubmitted || loading}
          placeholder={"Search for a specific location or address..."}
          postCodeSearch={true}
          onPlaceChanged={handleAutocompleteChanged}
          getFilteredPostCodes={getFilteredPostCodes}
        />
      </Box>
      {addresses.map((address) => {
        return (
          <AddressCardV2
            address={address}
            key={address.name}
            onClick={() => onAddressCardClick(address)}
            handleDeleteLocation={handleDeleteLocation}
            setSelectedRoutes={setSelectedRoutes}
            googleMap={googleMap}
            setShouldAutoSave={setShouldAutoSave}
            setIsUndoOrRedo={setIsUndoOrRedo}
            setIsSingleRouteSelection={setIsSingleRouteSelection}
          />
        );
      })}
      {!selectedRoutes.length && (
        <div className={classes.emptyStateContainer}>
          <EmptyAddressesState />
          <span className={classes.emptyStateText}>Click on the map areas to choose your delivery routes.</span>
        </div>
      )}
    </div>
  );
};
