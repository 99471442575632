import React, { useState, useEffect, useMemo } from "react";
import { usePostHog } from "posthog-js/react";
import { makeStyles, Slider } from "@material-ui/core";
import { EmitAnalyticsEvent } from "module/analytics/application/EmitAnalyticsEvent";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { UndoIcon, RedoIcon } from "pages/flyer/builder/drawer/components/Primitives/icons";
import { Cursor } from "components/icons/components/Cursor";
import { Ellipse } from "components/icons/components/Ellipse";
import { Saving } from "components/Saving";
import { MAP_TYPES } from "pages/constants";
import {
  calculateMinCircleRadius,
  calculateMaxCircleRadius,
  updateSelectedRoutesOnDataLayer,
} from "components/audience-map/utils";
import cn from "classnames";
import { useStore } from "store";
import { getSelectedRoutesFromDistributionLocations } from "../AudienceMapEDDM/utils";
import { styles } from "./styles";

const radiusButtonsLabels = {
  plus: "+",
  minus: "-",
};

const radiusOptions = [1, 2, 5, 10];

const useStyles = makeStyles(() => styles);

export const EDDMToolbar = ({
  radius,
  setRadius,
  isSaving,
  drawingMode,
  setDrawingMode,
  setShouldAutoSave,
  circleSelectorRadius,
  setCircleSelectorRadius,
  setIsUndoOrRedo,
  setSelectedRoutes,
  fetchRoutes,
  originator,
  caretaker,
  fetchRoutesAndUpdateMap,
  googleMap,
}) => {
  const posthog = usePostHog();
  const posthogEventTracker = new EmitAnalyticsEvent(posthog);
  const {
    campaign: { id: campaignId },
    user,
    client: { name: clientName },
    updateMap,
    setDistributionLocations,
    distributionLocations,
  } = useStore();
  const [openSlider, setOpenSlider] = useState(false);
  const classes = useStyles();

  const focusedLocation = useMemo(
    () => distributionLocations.find((location) => location.isFocused),
    [distributionLocations]
  );
  const disabledUndo = isSaving || caretaker.isUndoDisabled(campaignId);
  const disabledRedo = isSaving || caretaker.isRedoDisabled(campaignId);

  const handleMapRadiusChange = (value) => {
    const currentRadiusOption = radiusOptions.findIndex((r) => r === radius);
    if (value === radiusButtonsLabels.plus && radius < 10) {
      setShouldAutoSave(true);
      setIsUndoOrRedo(false);
      const newRadius = radiusOptions[currentRadiusOption + 1];

      posthogEventTracker.run({
        eventName: "Changed map radius using buttons",
        userId: user?.id,
        clientName,
        device: navigator.userAgent,
        additionalPayload: { mapRadius: newRadius },
      });

      setRadius(newRadius);
      fetchRoutesAndUpdateMap(focusedLocation, newRadius);
    } else if (value === radiusButtonsLabels.minus && radius > 1) {
      setShouldAutoSave(true);
      setIsUndoOrRedo(false);
      const newRadius = radiusOptions[currentRadiusOption - 1];

      posthogEventTracker.run({
        eventName: "Changed map radius using buttons",
        userId: user?.id,
        clientName,
        device: navigator.userAgent,
        additionalPayload: { mapRadius: newRadius },
      });
      setRadius(newRadius);
      fetchRoutesAndUpdateMap(focusedLocation, newRadius);
    }
  };

  const handleUndo = () => {
    if (disabledUndo) return;
    setIsUndoOrRedo(true);
    setShouldAutoSave(true);
    caretaker.undo(campaignId);

    posthogEventTracker.run({
      eventName: "Action undone on the map",
      userId: user?.id,
      clientName,
      device: navigator.userAgent,
      additionalPayload: { selectionType: drawingMode ? "circle" : "individual" },
    });
    handleUndoOrRedo();
  };

  const handleRedo = () => {
    if (disabledRedo) return;
    setIsUndoOrRedo(true);
    setShouldAutoSave(true);
    caretaker.redo(campaignId);

    posthogEventTracker.run({
      eventName: "Action re-done on the map",
      userId: user?.id,
      clientName,
      device: navigator.userAgent,
      additionalPayload: { selectionType: drawingMode ? "circle" : "individual" },
    });
    handleUndoOrRedo();
  };

  const handleUndoOrRedo = () => {
    const newDistributionLocations = originator.getState();
    setDistributionLocations(newDistributionLocations);

    const newFocusedLocation = newDistributionLocations.find((location) => location.isFocused);
    const newSelectedRoutes = getSelectedRoutesFromDistributionLocations(newDistributionLocations);
    setSelectedRoutes(newSelectedRoutes);
    if (
      !newFocusedLocation ||
      (newFocusedLocation?.name === focusedLocation?.name && newFocusedLocation?.radius === focusedLocation?.radius)
    ) {
      updateSelectedRoutesOnDataLayer({ dataLayer: googleMap?.data, selectedRoutes: newSelectedRoutes });
      return;
    }
    setRadius(newFocusedLocation.radius);
    updateMap({ loading: true, center: { lat: newFocusedLocation.lat, lng: newFocusedLocation.lng } });
    fetchRoutes({
      center: [newFocusedLocation.lng, newFocusedLocation.lat],
      radius: newFocusedLocation.radius * 1609.34,
    });
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
      if (isMac ? event.metaKey : event.ctrlKey) {
        if (event.key.toLowerCase() === "z" && !event.shiftKey) {
          // CMD/CTRL + Z
          event.preventDefault();
          handleUndo();
        } else if ((event.key.toLowerCase() === "z" && event.shiftKey) || event.key.toLowerCase() === "y") {
          // CMD/CTRL + SHIFT + Z
          event.preventDefault();
          handleRedo();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleUndo, handleRedo]);

  const increaseZoomDisabled = radius === 10;
  const decreaseZoomDisabled = radius === 1;
  const cursorSelected = drawingMode === null;
  const circleSelected = drawingMode === MAP_TYPES.CIRCLE;
  const minSliderValue = calculateMinCircleRadius(radius);
  const maxSliderValue = calculateMaxCircleRadius(radius);

  return (
    <div className={classes.toolbarContainer}>
      <div className={cn(classes.toolbarSection, classes.zoomSectionContainer)}>
        <span>Map radius</span>
        <div className={classes.zoomSection}>
          <div className={classes.zoomButtons}>
            <div className={cn(classes.zoomButton, classes.zoomMinus)}>
              <RemoveIcon
                onClick={() => handleMapRadiusChange(radiusButtonsLabels.minus)}
                className={cn({ [classes.disabled]: decreaseZoomDisabled })}
                disabled={decreaseZoomDisabled}
              />
            </div>
            <div className={cn(classes.zoomButton, classes.zoomPlus)}>
              <AddIcon
                onClick={() => handleMapRadiusChange(radiusButtonsLabels.plus)}
                className={cn({ [classes.disabled]: increaseZoomDisabled })}
                disabled={increaseZoomDisabled}
              />
            </div>
          </div>
          <span>{radius} miles</span>
        </div>
      </div>

      <div className={classes.separator} />

      <div className={cn(classes.toolbarSection, classes.selectorContainer)}>
        <span>Selection</span>
        <div className={classes.selectionSection}>
          <div
            className={cn(classes.selectionButton, { [classes.selectedButton]: drawingMode === null })}
            onClick={() => {
              posthogEventTracker.run({
                eventName: "Used route individual selection",
                userId: user?.id,
                clientName,
                device: navigator.userAgent,
              });
              setDrawingMode(null);
            }}
          >
            <Cursor stroke={cursorSelected ? "white" : "#1A2547"} /> <span>Route</span>
          </div>
          <div className={classes.buttonsSeparator} />
          <div
            className={cn(classes.selectionButton, { [classes.selectedButton]: drawingMode === MAP_TYPES.CIRCLE })}
            onClick={() => {
              posthogEventTracker.run({
                eventName: "Used multiple routes selection",
                userId: user?.id,
                clientName,
                device: navigator.userAgent,
              });
              setDrawingMode(MAP_TYPES.CIRCLE);
            }}
          >
            <Ellipse stroke={circleSelected ? "white" : "#1A2547"} />
            <span>Area</span>
          </div>
        </div>
      </div>
      {circleSelected && (
        <div className={classes.toolbarSection}>
          <span>Selector size</span>
          {openSlider && (
            <div className={classes.sliderContainer}>
              <div className={classes.sliderLabels}>
                <span>{`${minSliderValue} mi`}</span>
                <span>{`${maxSliderValue} mi`}</span>
              </div>
              <Slider
                value={circleSelectorRadius}
                min={minSliderValue}
                step={0.25}
                max={maxSliderValue}
                className={classes.slider}
                onChange={(e, value) => {
                  if (value === circleSelectorRadius) return;
                  posthogEventTracker.run({
                    eventName: "Used selector size adjustment",
                    userId: user?.id,
                    clientName,
                    device: navigator.userAgent,
                    additionalPayload: {
                      selectorSize: `${value}mi`,
                      mapRadius: `${radius} mile${radius > 1 ? "s" : ""}`,
                    },
                  });
                  setCircleSelectorRadius(value);
                }}
                onBlur={() => setOpenSlider(false)}
              />
            </div>
          )}
          <div
            className={cn(classes.selectionSection, classes.cursorPointer)}
            onClick={() => setOpenSlider(!openSlider)}
          >
            <span className={classes.sizeValue}>{circleSelectorRadius} mi</span>
            <div className={classes.buttonsSeparator} />
            {openSlider ? (
              <ExpandLessIcon className={classes.expandIcon} />
            ) : (
              <ExpandMoreIcon className={classes.expandIcon} />
            )}
          </div>
        </div>
      )}

      <div className={classes.savingContainer}>
        <Saving saving={isSaving} hideLabels />
      </div>

      <div className={classes.separator} />

      <div className={classes.undoRedoContainer}>
        <div className={classes.undoRedoButtons}>
          <UndoIcon
            width={20}
            height={20}
            stroke={disabledUndo ? "#E4E4EA" : "#4F5B94"}
            className={disabledUndo ? "" : classes.cursorPointer}
            onClick={handleUndo}
          />
          <RedoIcon
            width={20}
            height={20}
            stroke={disabledRedo ? "#E4E4EA" : "#4F5B94"}
            className={disabledRedo ? "" : classes.cursorPointer}
            onClick={handleRedo}
          />
        </div>
      </div>
    </div>
  );
};
