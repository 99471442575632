import { SelectedRoute, DistributionLocation } from "module/eddm/dto";

const getSelectedRoutesFromDistributionLocations = (distributionLocations: DistributionLocation[]) => {
  if (!distributionLocations || !distributionLocations.length) {
    return [];
  }
  const selectedRoutes: SelectedRoute[] = [];
  distributionLocations.forEach((location) => selectedRoutes.push(...location.selectedRoutes));
  return selectedRoutes;
};

const radiusToZoom: Record<number, number> = {
  1: 14,
  2: 13,
  5: 12,
  10: 11,
  30: 10,
};

export { getSelectedRoutesFromDistributionLocations, radiusToZoom };
