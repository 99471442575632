const ROUTING = {
  BLANK: "/",
  ACCESS_DENIED: "/access-denied",
  LOGIN: "/login",
  CAMPAIGN_CHANNEL: "/client/:clientId/campaign/:campaignId?/channel",
  CAMPAIGN: "/client/:clientId?/campaign/:campaignId?",
  CAMPAIGN_DETAILS: "/client/:clientId/campaign/:campaignId/details",
  CAMPAIGN_AUDIENCE: "/client/:clientId/campaign/:campaignId/audience",
  SUBMIT: "/client/:clientId/campaign/:campaignId/submit",
  SUCCESS: "/client/:clientId/campaign/:campaignId/success",
  FLYER: "/client/:clientId/flyer/:campaignId?",
  FLYER_BUILDER: "/client/:clientId/flyer/:campaignId?/builder",
  UPLOAD_FLYER: "/client/:clientId/flyer/:campaignId?/upload/:hideUploadedFile?",
  ERROR: "/error",
};

export const DM_ROUTING = {
  DM_CAMPAIGN: "/client/:clientId?/eddm/campaign/:campaignId?",
  DM_CAMPAIGN_DETAILS: "/client/:clientId/eddm/campaign/:campaignId/details",
  DM_CAMPAIGN_SEGMENTS: "/client/:clientId/eddm/campaign/:campaignId/segments",
  DM_SUBMIT: "/client/:clientId/eddm/campaign/:campaignId/submit",
  DM_SUCCESS: "/client/:clientId/eddm/campaign/:campaignId/success",
  DM_FLYER_BUILDER: "/client/:clientId/eddm/flyer/:campaignId?/builder",
  DM_FLYER: "/client/:clientId/eddm/flyer/:campaignId?",
  DM_UPLOAD_FLYER: "/client/:clientId/eddm/flyer/:campaignId?/upload",
};

export default ROUTING;
