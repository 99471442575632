import { useState, useEffect } from "react";
import cn from "classnames";
import { DistributionLocation, SelectedRoute } from "module/eddm/dto";
import { usePostHog } from "posthog-js/react";
import { EmitAnalyticsEvent } from "module/analytics/application/EmitAnalyticsEvent";
import { formatNumber, toFixed2 } from "utils/formators";
import { GoogleMap } from "@react-google-maps/api";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { makeStyles } from "@material-ui/core";
import { DeleteIcon } from "static/media/delete-icon";
import { useStore } from "store";
import { stylesV2 } from "./stylesV2";
import { RouteCard } from "./RouteCard";
import { AddressIconSelected, AddressIconUnselected } from "./icons";

const useStyles = makeStyles(() => stylesV2);

interface AddressCardProps {
  address: DistributionLocation;
  onClick: () => void;
  handleDeleteLocation: (deletedLocation: DistributionLocation) => void;
  setSelectedRoutes: (selectedRoutes: SelectedRoute[] | ((selectedRoutes: SelectedRoute[]) => SelectedRoute[])) => void;
  googleMap: GoogleMap | null;
  setShouldAutoSave: (shouldAutoSave: boolean) => void;
  setIsUndoOrRedo: (isUndoOrRedo: boolean) => void;
  setIsSingleRouteSelection: (isSingleSelection: boolean) => void;
}

export const AddressCardV2 = ({
  address,
  onClick,
  handleDeleteLocation,
  setSelectedRoutes,
  googleMap,
  setShouldAutoSave,
  setIsUndoOrRedo,
  setIsSingleRouteSelection,
}: AddressCardProps) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteDisabled, setDeleteDisabled] = useState(true);
  const { isFocused, name, selectedRoutes } = address;
  const [expanded, setExpanded] = useState(isFocused);
  const classes = useStyles();
  const {
    user: { id: userId },
    client: { name: clientName },
    campaign: { isSubmitted },
    costsCalculationData: { costPerFlyer },
  } = useStore();
  const posthog = usePostHog();
  const posthogEventTracker = new EmitAnalyticsEvent(posthog);

  useEffect(() => {
    if (isFocused) {
      setDeleteDisabled(false);
    }
  }, []);

  const handleRouteDelete = (route: SelectedRoute) => {
    if (!route || !googleMap) return;
    setShouldAutoSave(true);
    setIsUndoOrRedo(false);
    setIsSingleRouteSelection(true);
    setSelectedRoutes((selectedRoutes) => [...selectedRoutes.filter((r) => r.id !== route.id)]);
    // @ts-ignore
    googleMap.data.forEach((feature) => {
      if (route.id === feature.getProperty("id")) {
        feature.setProperty("selected", false);
      }
    });
  };

  const totalAddresses = selectedRoutes.reduce((acc, curr) => {
    return acc + curr.metadata.APT_COUNT + curr.metadata.HOME_COUNT;
  }, 0);

  return (
    <div className={cn(classes.addressCardContainer)}>
      <div className={classes.addressHeader}>
        <div className={cn(classes.addressTitle, { [classes.shortTitle]: isDeleting })} onClick={onClick}>
          {isDeleting ? <b>Delete</b> : isFocused ? <AddressIconSelected /> : <AddressIconUnselected />}
          <span className={classes.name}>{name}</span> {isDeleting ? "?" : ""}
        </div>
        {isDeleting ? (
          <div className={classes.deleteConfirmationButtonsWrapper}>
            <div
              className={classes.noButton}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsDeleting(false);
              }}
            >
              No
            </div>
            <div
              className={classes.yesButton}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                posthogEventTracker.run({
                  eventName: `Deleted entire location`,
                  userId: userId,
                  clientName,
                  device: navigator.userAgent,
                });
                handleDeleteLocation(address);
              }}
            >
              Yes
            </div>
          </div>
        ) : (
          <DeleteIcon
            disabled={deleteDisabled || isSubmitted}
            width={"16"}
            height={"16"}
            className={classes.cursorPointer}
            onMouseEnter={() => {
              setDeleteDisabled(false);
            }}
            onMouseLeave={() => {
              setDeleteDisabled(true);
            }}
            onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
              e.preventDefault();
              e.stopPropagation();
              if (isSubmitted) return;
              setIsDeleting(true);
            }}
          />
        )}
      </div>
      <div
        className={cn(classes.addressStatistics, {
          [classes.addressStatisticsCollapsed]: !expanded && !!selectedRoutes?.length,
          [classes.addressStatisticsCollapsedDeleting]: !expanded && !!selectedRoutes?.length && isDeleting,
        })}
      >
        <div>
          <span className={classes.statisticLabel}>Routes: </span>
          <span className={cn({ [classes.deletingLabel]: isDeleting })}>{selectedRoutes?.length || 0}</span>
        </div>
        <div>
          <span className={classes.statisticLabel}>Residences: </span>
          <span className={cn({ [classes.deletingLabel]: isDeleting })}>{formatNumber(totalAddresses)}</span>
        </div>
        <div>
          <span className={classes.statisticLabel}>Costs: </span>
          <span className={cn({ [classes.deletingLabel]: isDeleting })}>
            ${formatNumber(toFixed2(costPerFlyer * totalAddresses))}
          </span>
        </div>
      </div>
      {expanded &&
        !!selectedRoutes?.length &&
        selectedRoutes.map((route, i) => (
          <RouteCard
            key={`${route.metadata.ZIP}-${route.metadata.CROUTE}`}
            route={route}
            className={cn({
              [classes.firstRoute]: i === 0,
              [classes.lastRoute]: i === selectedRoutes.length - 1,
              [classes.lastRouteDeleting]: i === selectedRoutes.length - 1 && isDeleting,
            })}
            isDeleting={isDeleting}
            handleRouteDelete={handleRouteDelete}
          />
        ))}
      {!!selectedRoutes?.length && (
        <div className={classes.expandCollapseContainer}>
          <div
            className={classes.expandCollapseButton}
            onClick={() => {
              posthogEventTracker.run({
                eventName: `${expanded ? "Collapsed" : "Expanded"} addresses list`,
                userId: userId,
                clientName,
                device: navigator.userAgent,
              });
              setExpanded(!expanded);
            }}
          >
            <span className={classes.expandCollapseLabel}>See {expanded ? "less" : "more"}</span>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
        </div>
      )}
    </div>
  );
};
