export const stylesV2 = {
  addressCardContainer: {
    borderRadius: "8px",
    padding: "5px 8px",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
    position: "relative" as const,
  },
  deletingOverlap: {
    position: "absolute" as const,
    top: "0px",
    right: "0px",
    borderRadius: "8px",
    width: "0%",
    height: "100%",
    background: "#E6254D",
    transition: "width 0.2s ease-out",
  },
  deletingAnimation: {
    width: "100%",
  },
  addressHeader: {
    width: "100%",
    minHeight: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  addressTitle: {
    display: "flex",
    alignItems: "center",
    fontFamily: "'Proxima Nova', 'sans-serif'",
    maxWidth: "90%",
    cursor: "pointer",
  },
  shortTitle: {
    maxWidth: "65%",
  },
  name: {
    marginLeft: "5px",
    fontWeight: 400,
    fontFamily: "'Proxima Nova', 'sans-serif'",
    whiteSpace: "nowrap" as const,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  deleteConfirmationButtonsWrapper: {
    display: "flex",
  },
  noButton: {
    borderRadius: "4px",
    padding: "2px 15px",
    fontWeight: 800,
    fontSize: "12px",
    fontFamily: "'Proxima Nova', 'sans-serif'",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FFC9D3",
    },
  },
  yesButton: {
    borderRadius: "4px",
    padding: "2px 15px",
    backgroundColor: "#EE4360",
    marginLeft: "4px",
    color: "#FFFFFF",
    fontSize: "12px",
    fontWeight: 800,
    fontFamily: "'Proxima Nova', 'sans-serif'",
    cursor: "pointer",
  },

  addressStatistics: {
    width: "100%",
    position: "relative" as const,
    border: "1px solid transparent",

    display: "grid",
    gridTemplateColumns: "1.2fr 1.7fr 1.6fr",
    fontFamily: "'Proxima Nova', 'sans-serif'",
    fontSize: "12px",
    alignItems: "center",
    padding: "0px 0px 12px 12px",
  },
  addressStatisticsCollapsed: {
    padding: "12px 0px 12px 12px",
    height: "40px",
    background: "#F9F9FF",
    border: "1px solid #E2E2FC",
    borderRadius: "8px",
    boxShadow: "#F9F9FF 0px 3px 0px -1px, #E2E2FC 0px 3px, #F9F9FF 0px 6px 0px -1px, #E2E2FC 0px 6px",
  },

  addressStatisticsCollapsedDeleting: {
    border: "1px solid #FFA4B5",
    backgroundColor: "#FF798D1F",
    boxShadow: "#F9F9FF 0px 3px 0px -1px, #FFA4B5 0px 3px, #F9F9FF 0px 6px 0px -1px, #FFA4B5 0px 6px",
  },
  statisticLabel: {
    color: "#707087",
  },
  deletingLabel: {
    color: "red",
  },
  expandCollapseContainer: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  expandCollapseButton: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  expandCollapseLabel: {
    marginRight: "5px",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  firstRoute: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
  lastRoute: {
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    borderBottom: "1px solid #E2E2FC",
  },
  lastRouteDeleting: {
    borderBottom: "1px solid #FFA4B5",
  },
};
