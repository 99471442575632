export const OppiziLogoSVG = (props) => {
  return (
    <svg width="78" height="25" viewBox="0 0 78 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.2374 20.3394C17.6489 20.3394 20.2533 17.1557 20.2533 13.4865C20.2533 9.81725 17.6489 6.66062 13.2374 6.66062C8.87902 6.66062 6.24805 9.81725 6.24805 13.4865C6.24805 17.1557 8.87902 20.3394 13.2374 20.3394ZM13.2374 16.6701C11.4568 16.6701 10.4735 15.2402 10.4735 13.4865C10.4735 11.7598 11.4568 10.3299 13.2374 10.3299C15.0445 10.3299 16.0544 11.7598 16.0544 13.4865C16.0544 15.2402 15.0445 16.6701 13.2374 16.6701Z"
        fill="#EE4360"
      />
      <path
        d="M30.3034 20.3394C33.519 20.3394 35.9374 17.9112 35.9374 13.4865C35.9374 9.08879 33.519 6.66062 30.3034 6.66062C28.8417 6.66062 27.513 7.25417 26.5031 8.49524V6.98437H22.4105V24.9799H26.5031V18.4778C27.5661 19.7728 28.8683 20.3394 30.3034 20.3394ZM28.948 16.6701C28.071 16.6701 27.008 16.1575 26.5031 15.483V11.517C27.008 10.8155 28.071 10.3299 28.948 10.3299C30.5426 10.3299 31.765 11.544 31.765 13.4865C31.765 15.456 30.5426 16.6701 28.948 16.6701Z"
        fill="#EE4360"
      />
      <path
        d="M45.9528 20.3394C49.1684 20.3394 51.5868 17.9112 51.5868 13.4865C51.5868 9.08879 49.1684 6.66062 45.9528 6.66062C44.4911 6.66062 43.1624 7.25417 42.1525 8.49524V6.98437H38.0599V24.9799H42.1525V18.4778C43.2155 19.7728 44.5177 20.3394 45.9528 20.3394ZM44.5974 16.6701C43.7205 16.6701 42.6574 16.1575 42.1525 15.483V11.517C42.6574 10.8155 43.7205 10.3299 44.5974 10.3299C46.192 10.3299 47.4144 11.544 47.4144 13.4865C47.4144 15.456 46.192 16.6701 44.5974 16.6701Z"
        fill="#EE4360"
      />
      <path
        d="M55.7556 5.82424C57.0578 5.82424 58.0942 4.74505 58.0942 3.42305C58.0942 2.10104 57.0578 1.04883 55.7556 1.04883C54.4534 1.04883 53.417 2.10104 53.417 3.42305C53.417 4.74505 54.4534 5.82424 55.7556 5.82424ZM57.8019 20.0156V6.98437H53.7093V20.0156H57.8019Z"
        fill="#EE4360"
      />
      <path
        d="M71.1062 20.0156V16.4273H65.738L70.9999 9.92517V6.98437H60.29V10.5997H65.4457L60.29 16.9669V20.0156H71.1062Z"
        fill="#EE4360"
      />
      <path
        d="M75.6612 5.82424C76.9634 5.82424 77.9999 4.74505 77.9999 3.42305C77.9999 2.10104 76.9634 1.04883 75.6612 1.04883C74.359 1.04883 73.3226 2.10104 73.3226 3.42305C73.3226 4.74505 74.359 5.82424 75.6612 5.82424ZM77.7075 20.0156V6.98437H73.6149V20.0156H77.7075Z"
        fill="#EE4360"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.87212 3.37891C4.87212 3.37891 4.90557 5.38516 3.0368 5.47387C1.16837 5.56197 0 3.93953 0 3.93953C0 3.93953 4.63866 3.52676 4.87212 3.37891Z"
        fill="#EE4360"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1938 0.980469C11.1938 0.980469 6.9069 1.17959 5.02292 0.980469C5.02292 0.980469 6.51722 4.86078 3.69141 5.988C3.69141 5.988 5.38038 6.18746 6.84212 5.988C8.30353 5.78887 11.5516 5.02666 11.1938 0.980469Z"
        fill="#EE4360"
      />
    </svg>
  );
};
