import LZString from "lz-string";
import { DistributionLocation } from "../dto";

export class EDDMSegmentsOriginator {
  private state: DistributionLocation[];

  constructor(initialState: DistributionLocation[]) {
    this.state = initialState;
  }

  setState(state: DistributionLocation[]): void {
    this.state = state;
  }

  getState(): DistributionLocation[] {
    return this.state;
  }

  save(): EDDMSegmentsMemento {
    return new EDDMSegmentsMemento(this.state);
  }

  restore(memento: EDDMSegmentsMemento): void {
    this.state = memento.getState();
  }
}

export class EDDMSegmentsMemento {
  private readonly state: DistributionLocation[];

  constructor(state: DistributionLocation[]) {
    this.state = JSON.parse(JSON.stringify(state));
  }

  getState(): DistributionLocation[] {
    return this.state;
  }
}

export class EDDMSegmentsCaretaker {
  constructor(private originator: EDDMSegmentsOriginator) {}

  save(campaignId: string): void {
    const key = `campaign-${campaignId}`;
    const storedData = this.loadFromLocalStorage(campaignId);

    const currentIndex = storedData?.currentIndex ?? -1;
    const mementos = storedData?.mementos ?? [];

    // Remove all redo history
    const trimmedMementos = mementos.slice(0, currentIndex + 1);

    // Max history stack length is 4
    if (trimmedMementos.length > 4) {
      trimmedMementos.shift();
    }

    trimmedMementos.push(this.originator.save());

    localStorage.setItem(
      key,
      LZString.compressToUTF16(
        JSON.stringify({
          currentIndex: trimmedMementos.length - 1,
          mementos: trimmedMementos,
        })
      )
    );
  }

  undo(campaignId: string): void {
    const key = `campaign-${campaignId}`;
    const storedData = this.loadFromLocalStorage(campaignId);

    if (!storedData?.currentIndex || storedData.currentIndex < 0) return;

    const newIndex = storedData.currentIndex - 1;
    const memento = storedData.mementos[newIndex];

    this.originator.restore(memento);

    localStorage.setItem(
      key,
      LZString.compressToUTF16(
        JSON.stringify({
          ...storedData,
          currentIndex: newIndex,
        })
      )
    );
  }

  redo(campaignId: string): void {
    const key = `campaign-${campaignId}`;
    const storedData = this.loadFromLocalStorage(campaignId);

    if (!storedData || storedData.currentIndex >= storedData.mementos.length - 1) return;

    const newIndex = storedData.currentIndex + 1;
    const memento = storedData.mementos[newIndex];

    this.originator.restore(memento);

    localStorage.setItem(
      key,
      LZString.compressToUTF16(
        JSON.stringify({
          ...storedData,
          currentIndex: newIndex,
        })
      )
    );
  }

  isUndoDisabled(campaignId: string): boolean {
    const storedData = this.loadFromLocalStorage(campaignId);
    const mementos = storedData?.mementos || null;
    const disabledUndo = !mementos || storedData?.currentIndex === 0 || mementos.length === 1;
    return disabledUndo;
  }

  isRedoDisabled(campaignId: string): boolean {
    const storedData = this.loadFromLocalStorage(campaignId);
    const mementos = storedData?.mementos || null;
    const disabledRedo = !mementos || (!!storedData && mementos.length === storedData?.currentIndex + 1);
    return disabledRedo;
  }

  clearHistory(campaignId: string): void {
    const key = `campaign-${campaignId}`;
    localStorage.removeItem(key);
  }

  loadFromLocalStorage(campaignId: string): {
    currentIndex: number;
    mementos: EDDMSegmentsMemento[];
  } | null {
    const key = `campaign-${campaignId}`;
    const storedData = localStorage.getItem(key);

    if (storedData) {
      const decompressed = LZString.decompressFromUTF16(storedData);

      if (decompressed) {
        const parsedData = JSON.parse(decompressed);
        const mementos = parsedData.mementos.map(
          (memento: { state: DistributionLocation[] }) => new EDDMSegmentsMemento(memento.state)
        );

        return {
          currentIndex: parsedData.currentIndex,
          mementos,
        };
      }
    }

    return null;
  }
}
